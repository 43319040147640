import React from 'react';
import './style.css'
import {AiOutlineUserAdd} from 'react-icons/ai';


export default class AddChildCard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const l = this.props.labels;
        return (
            <div className={'child-card add-child-card add-child-gradient'} onClick={this.props.handleClick}>
                <div className={'add-child-card-overlay'}/>
                <AiOutlineUserAdd size={200} color={"#fff"}/>
                <span className={'add-child-label'}>{this.props.childCount > 0 ? l.addChild : l.addFirstChild}</span>
            </div>
        );
    }

}
