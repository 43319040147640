import React from 'react';
import '../style/kindertag-registration-main.css';
import {Alert, Button, Spinner} from "react-bootstrap";
import {labels as de} from "../languages/de";
import ParentsForm from "../components/forms/ParentsForm";
import ChildForm from "../components/forms/ChildForm";
import ChildCard from "../components/card/ChildCard";
import './style.css';
import './successAnimation.less';
import AddChildCard from "../components/card/AddChildCard";
import FooterNavigation from "../components/forms/FooterNavigation";
import ParentsCard from "../components/card/ParentsCard";
import AGBs from "../components/forms/AGBs";
import {ImCheckboxChecked, ImCheckboxUnchecked} from "react-icons/im";
import {FaInfoCircle} from "react-icons/fa";
import Payment from "../components/forms/Payment";


const _wpcf7 = '4583';
const _wpcf7_version = '5.5.4';
const _wpcf7_locale = 'de_DE';
const _wpcf7_unit_tag = 'wpcf7-f4583-p3130-o1';
const _wpcf7_container_post = '3130';

export default class KindertagRegistration extends React.Component {
	scrollToTop = React.createRef();

	constructor(props) {
		super(props);
		this.state = {
			labels: de, showLanguageModal: true, parentsData: {}, childsData: [], showAddChildModal: false, page: 1, errors: {}, payment: undefined,
			agbs: false, acceptImages: false, loading: false, success: false
		};
	}

	closeAddChildModal = () => this.setState({showAddChildModal: false});

	addChild = (data) => {
		this.setState({childsData: [...this.state.childsData, data]});
		this.closeAddChildModal();
	};

	removeChild = (index) => {
		let childs = this.state.childsData;
		childs.splice(index, 1);
		this.setState({childsData: childs});
	};

	getAddChildCard = () => {
		return <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
			<AddChildCard labels={this.state.labels} handleClick={() => this.setState({showAddChildModal: true})}/>
		</div>;
	};

	getParentsCard = () => {
		let data = this.state.parentsData;
		return <div className={'child-cards-container'}><ParentsCard name={data.name} street={data.street} city={data.city} phone={data.phone}
																	 mail={data.mail}/></div>;
	};

	getChildCards = (includeAdd) => {
		let cards = [];
		this.state.childsData.forEach((child, index) => {
			let childCard = <ChildCard
				showRemove={includeAdd}
				onRemove={(index) => this.removeChild(index)}
				labels={this.state.labels}
				childCount={index + 1}
				name={child.name}
				birth={child.birth}
				gender={child.gender}
				bringOwnSkates={child.bringOwnSkates}
				project={child.project}
				price={child.price}
			/>;
			cards.push(childCard);
		});
		return (<div className={'child-cards-container'}>{cards}</div>);
	};

	getTotalPrice = () => {
		let childs = this.state.childsData;
		let price = 0;
		childs.forEach(child => price += (child.price ? Number(child.price) : 0));
		return price;
	};

	getPayment = () => {
		let price = this.getTotalPrice();
		if (price > 0) {
			return <div className={'payment-container'}>
				<p>{price}€</p>
			</div>;
		}
	};


	checkRequiredFields = (errors, data, key) => {
		if (!data[key] || data[key].length <= 0) {
			errors[key] = this.state.labels.errors.required;
		}
	};

	verifyMail = (errors, data) => {
		if (data['mail'] !== data['mailVerify']) {
			errors['mailVerify'] = this.state.labels.errors.mailVerify;
		}
	};

	hasParentErrors = (errors) => {
		let parentErrors = {};
		let data = this.state.parentsData;

		this.checkRequiredFields(parentErrors, data, 'name');
		this.checkRequiredFields(parentErrors, data, 'street');
		this.checkRequiredFields(parentErrors, data, 'city');
		this.checkRequiredFields(parentErrors, data, 'phone');
		this.checkRequiredFields(parentErrors, data, 'mail');
		this.checkRequiredFields(parentErrors, data, 'mailVerify');
		this.verifyMail(parentErrors, data);

		if (!Object.keys(parentErrors).length) {
			return false;
		}

		errors.parentErrors = parentErrors;
		errors.parent = this.state.labels.errors.parent;
		return true;
	};

	changePage = (page) => {
		if (page === 2) {

			let errors = {};
			let parentHasErrors = this.hasParentErrors(errors);


			if (this.state.payment === undefined && this.getTotalPrice() > 0) {
				errors.payment = "Sie müssen eine Bezahlmethode auswählen";
			}

			if (!parentHasErrors && this.state.childsData.length > 0 && !errors.payment) {
				this.setState({page});
			}
			else {
				if (this.state.childsData.length <= 0) {
					errors.noChilds = this.state.labels.errors.noChilds;
				}

				this.setState({errors});
			}
		}
		else {
			this.setState({page});
		}

		this.scrollToTop.current.scrollIntoView();
	};

	/*
	 https://kfj-ulmen.de/wp-json/contact-form-7/v1/contact-forms/2313/feedback

	 _wpcf7: 2313
	 _wpcf7_version: 5.5.4
	 _wpcf7_locale: de_DE
	 _wpcf7_unit_tag: wpcf7-f2313-p2301-o1
	 _wpcf7_container_post: 2301
	 _wpcf7_posted_data_hash:
	 parent-name: Marcel Frank
	 parent-street: Gartenstr. 8
	 parent-city: 56761 Kaifenheim
	 parent-phone: 01606342895
	 parent-mail: mm.frank2017@gmail.com
	 child-name: Noel
	 child-birth: 15.10.2018
	 child-gender: Junge
	 child-kinderstunde: Gruppe 1
	 child-project: Töpfern
	 child-day: Montag (28.02.2022)
	 */
	sendToDatabase = (childData) => {
		let formData = new FormData();

		formData.append('_wpcf7', _wpcf7);
		formData.append('_wpcf7_version', _wpcf7_version);
		formData.append('_wpcf7_locale', _wpcf7_locale);
		formData.append('_wpcf7_unit_tag', _wpcf7_unit_tag);
		formData.append('_wpcf7_container_post', _wpcf7_container_post);

		let parentsData = this.state.parentsData;

		formData.append('parent-name', parentsData.name);
		formData.append('parent-street', parentsData.street);
		formData.append('parent-city', parentsData.city);
		formData.append('parent-phone', parentsData.phone);
		formData.append('parent-mail', parentsData.mail);

		formData.append('child-name', childData.name);
		formData.append('child-birth', de.getBirth(childData.birth));
		formData.append('child-gender', de.getGender(childData.gender));
		formData.append('child-project', de.getProject(childData.project));
		formData.append('child-price', childData.price ? `${childData.price}€` : "kostenlos");

		return fetch(`https://kfj-ulmen.de/wp-json/contact-form-7/v1/contact-forms/${_wpcf7}/feedback`, {
			method: 'POST', body: formData, headers: {
				accept: 'application/json, */*;q=0.1'
			}
		});
	};

	submit = () => {
		if (this.state.agbs) {
			this.setState({loading: true});
			let promises = [];
			this.state.childsData.forEach((childData) => {
				promises.push(this.sendToDatabase(childData));
			});

			Promise.all(promises).then((results) => {
				console.log("success");
			}).catch((err) => {
				console.log("error", err);
			}).finally(() => this.setState({success: true, loading: false}));
		}
		else {
			this.setState({errors: {...this.state.errors, agbs: this.state.labels.agbsError}});
		}
	};

	getChildsToRegisterInfo = () => {
		let childsList = [];
		this.state.childsData.forEach(child => childsList.push(<span>{child.name}<br/></span>));
		return <div>{childsList}</div>;
	};

	startAnimation = () => {
		this.setState({success: true, loading: false});
	};

	render() {
		const l = this.state.labels;
		return (<>
				{this.state.loading && <div className={'loading-container'}>
					<div><h1 style={{color: '#ffffff'}}>{l.pleaseWait}</h1></div>
					<div><p>{l.registrationInfo}</p></div>
					<div>{this.getChildsToRegisterInfo()}</div>
					<div className={'loading-spinner'}>
						<Spinner animation="grow" variant="info"/>
						<Spinner animation="grow" variant="info"/>
						<Spinner animation="grow" variant="info"/>
					</div>
				</div>}

				{this.state.success && <div className={'loading-container'}>
					<div className="success-checkmark">
						<div className="check-icon">
							<span className="icon-line line-tip"></span>
							<span className="icon-line line-long"></span>
							<div className="icon-circle"></div>
							<div className="icon-fix"></div>
						</div>
					</div>
					<div><h1 style={{color: '#ffffff'}}>{l.registrationSuccess}</h1></div>
					<div><h2 style={{color: '#ffffff'}}>{l.checkYourMails}</h2></div>
					{this.getTotalPrice() > 0 && <Button variant={'success'}
														 onClick={() => window.open(
															 `https://kfj-ulmen.de/kindertag-bezahlung?total=${this.getTotalPrice()}&payment=${this.state.payment}`,
															 "_self")}>{l.payNow}</Button>}
					{this.getTotalPrice() <= 0 &&
						<Button variant={'success'} onClick={() => window.open('https://kfj-ulmen.de/informationen-kindertag/', "_self")}>{l.ok}</Button>}
				</div>}

				<div className={'registration-container'}>
					<div className={'topic-container'}>
						<h2 ref={this.scrollToTop}>{l.topic}</h2>
					</div>
					{this.state.page === 1 ? <>

						<Alert key={'deadline'} variant={'danger'}>
							<FaInfoCircle/> {l.deadline}
						</Alert>
						<ParentsForm data={this.state.parentsData} onChange={(data) => this.setState({parentsData: data, errors: {}})}
									 errors={this.state.errors.parentErrors || {}} labels={l}/>
						{this.getChildCards(true)}
						{this.getAddChildCard()}
						{this.getTotalPrice() > 0 && <Payment description={"Bitte wählen Sie aus, wie Sie bezahlen wollen."} totalPrice={this.getTotalPrice()}
															  payment={this.state.payment} updatePayment={(value) => this.setState({payment: value})}/>}
						{this.state.showAddChildModal &&
							<ChildForm childCount={this.state.childsData.length + 1} close={this.closeAddChildModal} add={this.addChild}
									   show={this.state.showAddChildModal} labels={l}/>}
						{Object.keys(this.state.errors).length > 0 && <Alert key={"invalid"} variant={"danger"}>
							<p>{this.state.errors['noChilds']}</p>
							<p>{this.state.errors['parent']}</p>
							<p>{this.state.errors['payment']}</p>
						</Alert>}
					</> : <>
						<div className={'topic-container'}>
							<h3>{l.parents.topic}</h3>
						</div>
						{this.getParentsCard()}
						<div className={'topic-container'}>
							<h3>{l.childsTopic}</h3>
						</div>
						{this.getChildCards()}
						{this.getTotalPrice() > 0 && <Payment readOnly totalPrice={this.getTotalPrice()} payment={this.state.payment}
															  updatePayment={(value) => this.setState({payment: value})}/>}

						<div className={'agb-container ' + (this.state.errors.agbs ? 'error' : '')}>
							<AGBs labels={l}/>
							<Button variant={'subtle'} onClick={() => this.setState({agbs: !this.state.agbs, errors: {}})}>
								{this.state.agbs ? <ImCheckboxChecked/> : <ImCheckboxUnchecked/>} {l.agbCheck}
							</Button><br/>
							<Button variant={'subtle'} onClick={() => this.setState({acceptImages: !this.state.acceptImages})}>
								{this.state.acceptImages ? <ImCheckboxChecked/> : <ImCheckboxUnchecked/>} {l.acceptImages}
							</Button>
							{this.state.errors.agbs && <div className={'agb-error-message'}>{l.agbsError}</div>}
						</div>
					</>}
					<FooterNavigation labels={this.state.labels} switchPage={(page) => this.changePage(page)} register={() => this.submit()}
									  page={this.state.page}/>
				</div>
			</>);
	}

}
