import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import KindertagRegistration from "./pages/KindertagRegistration";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/Pangolin-Regular.ttf';
import {KindertagPayment} from "./pages/KindertagPayment";


if (document.getElementById('kindertag-registration')) {
	ReactDOM.render(
		<React.StrictMode>
			<KindertagRegistration/>
		</React.StrictMode>,
		document.getElementById('kindertag-registration')
	);
}

if (document.getElementById('kindertag-payment')) {
	ReactDOM.render(
		<React.StrictMode>
			<KindertagPayment/>
		</React.StrictMode>,
		document.getElementById('kindertag-payment')
	);
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
