import React from 'react';
import Image from 'react-bootstrap/Image';
import girl from '../../assets/images/girl.png';
import boy from '../../assets/images/boy.png';
import './style.css';
import {Button} from "react-bootstrap";

export default class ChildCard extends React.Component {
	constructor(props) {
		super(props);
	}

	getSkatesStatus() {
		if (this.props.project === "2") {
			return this.props.bringOwnSkates ? "Schlittschuhe vorhanden" : "Schlittschuhe werden ausgeliehen";
		}
		return undefined;
	}

	render() {
		const l = this.props.labels;
		return (
			<div className={'child-card ' + (this.props.gender === "m" ? 'girl' : 'boy')}>
				<div className={'child-image'}>
					<Image src={this.props.gender === "m" ? girl : boy}/>
				</div>
				<div className={'child-data'}>
					<h2 style={{color: '#000'}}>{this.props.name}</h2>
					<p><b>{l.getBirth(this.props.birth)}</b></p>
					<p><b>{l.getGender(this.props.gender)}</b></p>
					<p><b>{l.getProjectInfo(this.props.project)?.name}</b></p>
					{this.getSkatesStatus() && <p>{this.getSkatesStatus()}</p>}
					<p><b>Gebühr: {this.props.price ? this.props.price + "€" : "kostenlos"}</b></p>
					{this.props.showRemove && <>
						<hr/>
						<div className={'child-actions'}>
							<Button variant={'outline-danger'} style={{color: '#000', width: '100%'}}
									onClick={() => this.props.onRemove(this.props.childCount - 1)}>{l.delete}</Button>
						</div>
					</>}
				</div>
			</div>
		);
	}

}
