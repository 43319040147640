import React from 'react';
import {Button} from "react-bootstrap";

export default class FooterNavigation extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let l = this.props.labels;
        return (
            <div className={'footer-navigation'}>
                {this.props.page === 1 && <Button onClick={() => this.props.switchPage(2)}>{l.continue}</Button>}
                {this.props.page === 2 &&
                <>
                    <Button variant={'secondary'} onClick={() => this.props.switchPage(1)}>{l.back}</Button>
                    <Button style={{marginLeft: '20px'}} onClick={this.props.register}>{l.complete}</Button>
                </>}
            </div>
        );
    }

}
