import React from 'react';
import FormCard from "../card/FormCard";
import {Form} from "react-bootstrap";

export default class ParentsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.data.name || '',
            street: this.props.data.street || '',
            city: this.props.data.city || '',
            phone: this.props.data.phone || '',
            mail: this.props.data.mail || '',
            mailVerify: this.props.data.mailVerify || ''
        }
    }

    getParentsData = (data) => {
        let fullData = {
            name: data.name !== undefined ? data.name : this.state.name,
            street: data.street !== undefined ? data.street : this.state.street,
            city: data.city !== undefined ? data.city : this.state.city,
            phone: data.phone !== undefined ? data.phone : this.state.phone,
            mail: data.mail !== undefined ? data.mail : this.state.mail,
            mailVerify: data.mailVerify !== undefined ? data.mailVerify : this.state.mailVerify
        }
        this.setState(fullData)
        return fullData;
    }

    render() {
        const l = this.props.labels;
        return (
            <FormCard title={l.parents.topic}>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>{l.parents.name}</Form.Label>
                        <Form.Control
                            value={this.state.name}
                            onChange={(e) => this.props.onChange(this.getParentsData({name: e.currentTarget.value}))}
                            type="text"
                            isInvalid={this.props.errors['name']}
                            placeholder={l.parents.placeholder.name}/>
                        <Form.Control.Feedback type="invalid">{this.props.errors['name']}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{l.parents.street}</Form.Label>
                        <Form.Control
                            value={this.state.street}
                            onChange={(e) => this.props.onChange(this.getParentsData({street: e.currentTarget.value}))}
                            type="text"
                            isInvalid={this.props.errors['street']}
                            placeholder={l.parents.placeholder.street}/>
                        <Form.Control.Feedback type="invalid">{this.props.errors['street']}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{l.parents.city}</Form.Label>
                        <Form.Control
                            value={this.state.city}
                            onChange={(e) => this.props.onChange(this.getParentsData({city: e.currentTarget.value}))}
                            type="text"
                            isInvalid={this.props.errors['city']}
                            placeholder={l.parents.placeholder.city}/>
                        <Form.Control.Feedback type="invalid">{this.props.errors['city']}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{l.parents.phone}</Form.Label>
                        <Form.Control
                            value={this.state.phone}
                            onChange={(e) => this.props.onChange(this.getParentsData({phone: e.currentTarget.value}))}
                            type="number"
                            isInvalid={this.props.errors['phone']}
                            placeholder={l.parents.placeholder.phone}/>
                        <Form.Control.Feedback type="invalid">{this.props.errors['phone']}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{l.parents.mail}</Form.Label>
                        <Form.Control
                            value={this.state.mail}
                            onChange={(e) => this.props.onChange(this.getParentsData({mail: e.currentTarget.value}))}
                            type="email"
                            isInvalid={this.props.errors['mail']}
                            placeholder={l.parents.placeholder.mail}/>
                        <Form.Control.Feedback type="invalid">{this.props.errors['mail']}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{l.parents.mailVerify}</Form.Label>
                        <Form.Control
                            value={this.state.mailVerify}
                            onChange={(e) => this.props.onChange(this.getParentsData({mailVerify: e.currentTarget.value}))}
                            type="email"
                            isInvalid={this.props.errors['mailVerify']}
                            placeholder={l.parents.placeholder.mail}/>
                        <Form.Control.Feedback type="invalid">{this.props.errors['mailVerify']}</Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </FormCard>
        );
    }

}
