import React from 'react';
import './style.css';


/**
 * Props:
 * title : string
 * description? : string
 */

export default class FormCard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={'form-card'}>
                <h3>{this.props.title}</h3>
                {this.props.description && <p>{this.props.description}</p>}
                <>
                    {this.props.children}
                </>
            </div>
        );
    }

}
