import React from "react";
import rein_fuer_die_speise from "../assets/images/rein_fuer_die_speise.png";
import jesus_der_schoepfer from "../assets/images/jesus_der_schoepfer.png";
import ton_projekt from "../assets/images/ton_projekt.png";
import docking_station from "../assets/images/docking_station.png";
import kraftgeber_montag from "../assets/images/kraftgeber_montag.png";
import kraftgeber_sonntag from "../assets/images/kraftgeber_sonntag.png";
import puzzle3d from "../assets/images/3d_puzzle.jpg";

export const labels = {
    topic: "Kindertag - Anmeldung",
    language: "Sprache",
    parents: {
        topic: "Eltern",
        name: "Vor- und Nachname",
        street: "Straße",
        city: "PLZ/Ort",
        phone: "Telefon/Handy",
        mail: "E-Mail",
        mailVerify: "E-Mail wiederholen",
        placeholder: {
            name: "Max Mustermann",
            street: "Musterstraße 1",
            city: "12345 Musterstadt",
            phone: "01606342895",
            mail: "max.mustermann@muster.de"
        }
    },
    childsTopic: 'Kinder',
    child: {
        topic: (count = 1) => `Kind ${count}`,
        name: "Vorname",
        birth: "Geburtstag",
        gender: "Geschlecht",
        kinderstunde: "Kinderstunde",
        project: "Projekt",
        placeholder: {
            name: "Friedrich",
            kinderstunde: "Kinderstunde wählen",
            project: "Projekt wählen",
        }
    },
    addChild: 'Weiteres Kind hinzufügen',
    addFirstChild: 'Kind hinzufügen',
    add: 'Hinzufügen',
    cancel: 'Abbrechen',
    delete: 'Entfernen',
    ok: 'Verstanden',
    payNow: 'Jetzt bezahlen',
    continue: 'Weiter',
    back: 'Zurück',
    complete: 'Anmeldung abschließen',
    onlySunday: '(nur Sonntags)',
    errors: {
        mailVerify: 'Die angegebenen E-Mails stimmen nicht überein.',
        noChilds: 'Bitte fügen Sie erst mindestens ein Kind zur Anmeldung hinzu.',
        parent: 'Ein oder mehrere Felder sind fehlerhaft. Bitte überprüfen Sie Ihre Eingaben.',
        required: 'Dieses Feld ist ein Pflichtfeld.'
    },
    getProject: (project) => {
        switch (project) {
            case "0":
                return "Bambinis"
            case "1":
                return "Stationen in der Gemeinde"
            case "2":
                return "Schlittschuhlaufen"
            case "-1":
                return "Dein Kind ist leider zu alt oder zu jung für den Kindertag."
            default:
                return undefined;
        }
    },
    getGender: (gender) => {
        switch (gender) {
            case "j":
                return 'Junge'
            case "m":
                return 'Mädchen'
            default:
                return undefined;
        }
    },
    getBirth: (birth) => {
        let birthParts = birth.split("-");
        let day = birthParts[2];
        let month = birthParts[1];
        let year = birthParts[0];
        return `${day}.${month}.${year}`
    },
    getProjectInfo: (project) => {
        switch (project.toString()) {
            case "0":
                return {
                    name: "Bambinis"
                }
            case "1":
                return {
                    name: "Stationen in der Gemeinde"
                }
            case "2":
                return {
                    name: "Schlittschuhlaufen"
                }
            case "-1":
                return {
                    name: "Dein Kind ist leider zu alt oder zu jung für den Kindertag.",
                }
            default:
                return undefined;
        }
    },
    deadline: "Anmeldefrist: 16. Februar 2025",
    agbs: "Teilnahmebedingungen",
    agbCheck: "Ich habe meine Daten überprüft und bin mit den Teilnahmebedingungen einverstanden.",
    acceptImages: "Ich bin damit einverstanden, dass mein Kind auf Videos und Fotos online zu sehen ist.",
    agbsError: "Sie müssen den Teilnahmebedingungen zustimmen um teilzunehmen.",
    pleaseWait: "Bitte warten",
    registrationInfo: "Folgende Anmeldungen werden gerade verschickt:",
    registrationSuccess: "Die Anmeldung war erfolgreich!",
    checkYourMails: "Bitte überprüfen Sie Ihre E-Mails.",
    projectInfo: "Projekt Informationen"
}
