import React from 'react';
import {Accordion} from "react-bootstrap";
import './style.css'

export default class AGBs extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let l = this.props.labels;
        return (
            <>
                <div style={{marginBottom: '20px'}}>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>{l.agbs}</Accordion.Header>
                            <Accordion.Body>
                                Ich bin damit einverstanden, dass meine angegebenen Daten online gespeichert und für den Kindertag verwendet werden dürfen.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div style={{marginBottom: '20px'}}>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Haftung</Accordion.Header>
                            <Accordion.Body>
                                Die Inanspruchnahme des Kindertages erfolgt auf eigene Gefahr. Die betreuenden Personen übernehmen keinen Versicherungsschutz.
                                Jede Haftung der betreuenden Personen für Personen- und Sachschäden ist ausgeschlossen. Mit der Inanspruchnahme des
                                Betreuungsangebotes wird dieser Haftungsausschuss akzeptiert.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </>
        );
    }

}
