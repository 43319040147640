import React from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import './style.css';
import ProjectInfo from "../../data/ProjectInfo";
import {ImCheckboxChecked, ImCheckboxUnchecked} from "react-icons/im";

export default class ChildForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			birth: undefined,
			gender: 'j',
			bringOwnSkates: false,
			readEislaufordnung: false,
			project: undefined,
			price: undefined,
			errors: {}
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.birth !== this.state.birth) {
			this.calculateProject(this.state.birth);
		}

		if (prevState.project !== this.state.project) {
			this.setState({price: this.state.project !== "2" ? undefined : "5"});
		}
	}

	getChildData = () => {
		return {
			name: this.state.name,
			birth: this.state.birth,
			gender: this.state.gender,
			bringOwnSkates: this.state.bringOwnSkates,
			project: this.state.project,
			price: this.state.price
		};
	};

	checkRequiredFields = (errors, data, key) => {
		if (!data[key] || data[key].length <= 0) {
			errors[key] = this.props.labels.errors?.required;
		}
	};

	add = () => {
		let errors = {};
		let data = this.getChildData();

		this.checkRequiredFields(errors, data, 'name');
		this.checkRequiredFields(errors, data, 'birth');
		this.checkRequiredFields(errors, data, 'gender');
		this.checkRequiredFields(errors, data, 'project');

		if (this.state.project === "-1") {
			errors['birth'] = this.props.labels.getProjectInfo(this.state.project).name;
		}
		if (this.isIceSkating(this.state.birth) && !this.state.readEislaufordnung) {
			errors['eislaufordnung'] = "Die Eislaufordnung muss gelesen und akzeptiert werden.";
		}

		if (!Object.keys(errors).length) {
			this.props.add(data);
		}
		else {
			this.setState({errors});
		}
	};

	isBambini = (birth) => {
		const birthDate = new Date(birth);
		const startDate = new Date('2018-09-01');
		const endDate = new Date('2021-03-31');
		return birthDate >= startDate && birthDate <= endDate;
	};

	isStations = (birth) => {
		const birthDate = new Date(birth);
		const startDate = new Date('2012-09-01');
		const endDate = new Date('2018-08-18');
		return birthDate >= startDate && birthDate <= endDate;
	};

	isIceSkating = (birth) => {
		const birthDate = new Date(birth);
		const startDate = new Date('2009-01-01');
		const endDate = new Date('2012-08-31');
		return birthDate >= startDate && birthDate <= endDate;
	};

	calculateProject = (birth) => {
		if (this.isBambini(birth)) {
			this.setState({project: "0"});
		}
		else if (this.isStations(birth)) {
			this.setState({project: "1"});
		}
		else if (this.isIceSkating(birth)) {
			this.setState({project: "2"});
		}
		else {
			this.setState({project: "-1"});
		}
	};

	render() {
		const l = this.props.labels;
		return (
			<Modal show={this.props.show} centered dialogClassName="modal-add-child">
				<h2>{l.child.topic(this.props.childCount)}</h2>
				<Form>
					<Form.Group className="mb-3">
						<Form.Label>{l.child.name}</Form.Label>
						<Form.Control
							value={this.state.name}
							onChange={(e) => this.setState({name: e.currentTarget.value, errors: {}})}
							type="text"
							isInvalid={this.state.errors['name']}
							placeholder={l.child.placeholder.name}/>
						<Form.Control.Feedback type="invalid">{this.state.errors['name']}</Form.Control.Feedback>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>{l.child.birth}</Form.Label>
						<Form.Control
							value={this.state.birth}
							onChange={(e) => this.setState({birth: e.currentTarget.value, errors: {}})}
							isInvalid={this.state.errors['birth']}
							type="date"/>
						<Form.Control.Feedback type="invalid">{this.state.errors['birth']}</Form.Control.Feedback>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>{l.child.gender}</Form.Label>
						<Form.Select
							isInvalid={this.state.errors['gender']}
							value={this.state.gender}
							onChange={(e) => this.setState({gender: e.currentTarget.value, errors: {}})}>
							<option value="j">{l.getGender("j")}</option>
							<option value="m">{l.getGender("m")}</option>
						</Form.Select>
						<Form.Control.Feedback type="invalid">{this.state.errors['gender']}</Form.Control.Feedback>
					</Form.Group>

					{this.state.project && <div className={'project-info-container'}>
						<ProjectInfo labels={l} project={this.state.project} day={this.state.day}/>
					</div>}

					{this.state.birth && this.isIceSkating(this.state.birth) && (
						<>
							<div>
								<Form.Group className="mb-3">
									<Button variant={'subtle'} onClick={() => this.setState({readEislaufordnung: !this.state.readEislaufordnung})}>
										{this.state.readEislaufordnung ? <ImCheckboxChecked/> : <ImCheckboxUnchecked/>} <span>Ich habe die <a
										href="https://www.icehouse.de/eislaufordnung/" target={"_blank"}>Eislaufordnung</a> der Eishalle gelesen und bin damit einverstanden.</span>
									</Button>
									<p style={{
										color: "red", paddingLeft: "1rem", display: this.state.errors['eislaufordnung'] ? "" : "none"
									}}>{this.state.errors['eislaufordnung']}</p>
								</Form.Group>
							</div>
							<div>
								<Button variant={'subtle'} onClick={() => this.setState({bringOwnSkates: !this.state.bringOwnSkates})}>
									{this.state.bringOwnSkates ? <ImCheckboxChecked/> :
										<ImCheckboxUnchecked/>} {"Ich bringe eigene Schlittschuhe mit und muss keine ausleihen"}
								</Button>
							</div>
						</>
					)}
				</Form>

				<div className={'button-container'}>
					<Button variant="secondary" onClick={this.props.close}>{l.cancel}</Button>
					<Button className={'add-child-form-button'} onClick={() => this.add()}>{l.add}</Button>
				</div>
			</Modal>
		);
	}

}
