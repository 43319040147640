import React from 'react';
import './style.css'

export default class ParentsCard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={'parent-card'}>
                <div className={'parent-data'}>
                    <h2 style={{color: '#000'}}>{this.props.name}</h2>
                    <hr/>
                    <p><b>{this.props.street}</b></p>
                    <p><b>{this.props.city}</b></p>
                    <p><b>{this.props.phone}</b></p>
                    <p><b>{this.props.mail}</b></p>
                </div>
            </div>
        );
    }

}
