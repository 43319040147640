import React from 'react';
import {Card} from "react-bootstrap";
import './style.less'

export default class ProjectInfo extends React.Component {
    getProjectInfo = () => {
        return this.props.labels.getProjectInfo(this.props.project)
    }

    render() {
        return (
            <div>
                <p><b>Projekt: </b>{this.getProjectInfo()?.name}</p>
            </div>
        );
    }

}
