import React from 'react';
import {ButtonGroup, Form, ToggleButton} from "react-bootstrap";
import FormCard from "../card/FormCard";

const radios = [
    {name: 'Überweisung', value: 'transfer'},
    {name: 'PayPal', value: 'paypal'},
];

class Payment extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <FormCard title={`Bezahlung (Gesamtbetrag: ${this.props.totalPrice}€)`} description={this.props.description}>
                <ButtonGroup>
                    {radios.map((radio, idx) => (
                        <ToggleButton
                            disabled={this.props.readOnly}
                            key={`payment-${idx}`}
                            id={`payment-radio-${idx}`}
                            type="radio"
                            variant={'outline-success'}
                            name="payment"
                            value={radio.value}
                            checked={this.props.payment === radio.value}
                            onChange={(e) => this.props.updatePayment(e.currentTarget.value)}
                        >
                            {radio.name}
                        </ToggleButton>
                    ))}
                </ButtonGroup>
                <br/>
                {this.props.payment === "transfer" && <Form.Text className="text-muted">
                    Nach der erfolgreichen Anmeldung, erhalten Sie weitere Anweisungen um die Überweisung tätigen zu können.
                </Form.Text>}
                {this.props.payment === "paypal" && <Form.Text className="text-muted">
                    Bitte im Verwendungszweck die Namen aller Kinder angeben und wählen Sie beim Zahlen „Freunde und Familie“, damit keine Gebühren berechnet werden
                </Form.Text>}
            </FormCard>
        );
    }

}

export default Payment;
