import React, {useEffect, useState} from 'react';
import {Accordion, Button, Modal, Table} from "react-bootstrap";
import './style.css';

const labels = {
	topics: {
		payment: "Bezahlung",
		transfer: "Überweisung",
		paypal: "PayPal"
	},
	tableHeaders: {
		holder: "Kontoinhaber",
		iban: "IBAN",
		bic: "BIC",
		intendedUse: "Verwendungszweck"
	},
	transfer: {
		holder: "KINDER FÜR JESUS-ULMEN E.V.",
		iban: "DE71 5875 1230 0032 6189 77",
		bic: "MALADE51BKS",
		intendedUse: "Namen aller Kinder"
	},
	paypal: {
		info: "Bitte im Verwendungszweck die Namen aller Kinder angeben und wählen Sie beim Zahlen „Freunde und Familie“, damit keine Gebühren berechnet werden.",
		pay: "Per PayPal bezahlen",
		modal: {
			topic: "Per PayPal bezahlen",
			info: "Bitte denken Sie daran “Geld an Freunde oder Familie senden” auszuwählen, da sonst Gebühren anfallen und nicht die volle Summe überwiesen wird.",
			cancel: "Abbrechen",
			next: "Weiter zur Bezahlung"
		}
	}
};


export const KindertagPayment = () => {

	const [loading, setLoading] = useState(true);
	const [show, setShow] = useState(false);
	const [payment, setPayment] = useState(null);
	const [total, setTotal] = useState(null);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		setPayment(urlParams.get('payment'));
		setTotal(urlParams.get('total'));
		setLoading(false);
	}, []);

	const getPaymentLabel = () => {
		switch (payment) {
			case "transfer":
				return labels.topics.transfer;
			case "paypal":
				return labels.topics.paypal;
			default:
				return null;
		}
	};

	const dynamicPaymentInfo = () => {
		if (!payment || !total) {
			return;
		}

		return <>
			<div className={"infoContainer"} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
				<h4>Sie haben <b><u>{getPaymentLabel()}</u></b> als Zahlungsmethode ausgewählt.</h4>
				<hr/>
				<h5>Bitte zahlen Sie den Betrag von</h5>
				<h2>{total}€</h2>
				<h5>so schnell wie möglich, um uns die Organisation zu erleichtern.</h5>
				<p></p>
			</div>
		</>;
	};

	const getPaymentOptions = (alternative = false) => {
		const showTransfer = (!payment || payment === "transfer") && !alternative || (payment === "paypal" && alternative);
		const showPaypal = (!payment || payment === "paypal") && !alternative || (payment === "transfer" && alternative);

		return <>
			{showTransfer && <div className={"infoContainer transfer"}>
				<h4>{labels.topics.transfer}</h4>
				<Table striped bordered hover>
					<tbody>
					<tr>
						<th>{labels.tableHeaders.holder}</th>
						<td>{labels.transfer.holder}</td>
					</tr>
					<tr>
						<th>{labels.tableHeaders.iban}</th>
						<td>{labels.transfer.iban}</td>
					</tr>
					<tr>
						<th>{labels.tableHeaders.bic}</th>
						<td>{labels.transfer.bic}</td>
					</tr>
					<tr>
						<th>{labels.tableHeaders.intendedUse}</th>
						<td>{labels.transfer.intendedUse}</td>
					</tr>
					</tbody>
				</Table>
			</div>}
			{showPaypal && <div className={"infoContainer paypal"}>
				<h4>{labels.topics.paypal}</h4>
				<p>{labels.paypal.info}</p>
				<Button onClick={() => setShow(true)}>{labels.paypal.pay}</Button>
			</div>}
		</>
	}

	return (
		<div style={{display: "flex", flexDirection: "column", alignItems: "center", padding: "2rem"}}>
			<div style={{maxWidth: "800px", width: "100%"}}>
				{!loading && <div>
					<h2>{labels.topics.payment}</h2>
					{dynamicPaymentInfo()}
					{getPaymentOptions()}

					{payment === "transfer" || payment === "paypal" && <Accordion>
						<Accordion.Item eventKey="0">
							<Accordion.Header>Alternatives Zahlungsmittel</Accordion.Header>
							<Accordion.Body>
								{getPaymentOptions(true)}
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>}

					<Modal show={show} onHide={() => setShow(false)} centered>
						<Modal.Header closeButton>
							<Modal.Title>{labels.paypal.modal.topic}</Modal.Title>
						</Modal.Header>
						<Modal.Body>{labels.paypal.modal.info}</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={() => setShow(false)}>
								{labels.paypal.modal.cancel}
							</Button>
							<Button variant="primary" onClick={() => window.open("https://www.paypal.com/paypalme/kinderfuerjesus/" + (total ?? ""))}>
								{labels.paypal.modal.next}
							</Button>
						</Modal.Footer>
					</Modal>
				</div>}
			</div>
		</div>
	);
};